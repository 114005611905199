import React from "react"
import { FaHome, FaUserPlus, FaCheck, FaListUl, FaRegSun, FaFileAlt, FaUpload, FaFileMedical , FaFileExcel, FaBalanceScaleRight } from 'react-icons/fa'
// import { AiFillNotification, AiFillDatabase, AiOutlineOrderedList, AiOutlineUsergroupAdd } from 'react-icons/ai'
const user = JSON.parse(sessionStorage.getItem('user'))

const navigatorAgent = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    type: "groupHeader",
    groupTitle: "MÓDULO CONFIGURACIÓN"
  },
  {
    id: "sistem",
    title: "Sistema",
    type: "collapse",
    icon: <FaListUl size={20} />,
    permissions: ["admin", "editor"],
    badge: "warning",
    badgeText: ".",
    children: [
      {
        id: "1",
        title: "Gestión usuario",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/user",
      }
    ]
  },
  {
    id: "config",
    title: "Configuración",
    type: "collapse",
    icon: <FaRegSun size={20} />,
    permissions: ["admin", "editor"],
    badge: "warning",
    badgeText: ".",
    children: [
        {
        id: "1",
        title: "Tipo producto",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/product",
      },
      {
        id: "2",
        title: "Gestión compañia",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/company",
      },
      {
        id: "3",
        title: "Sectores",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/Sector",
      },
      {
        id: "5",
        title: "Grupo empresarial",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/GrupoEmpresarial",
      },
      {
        id: "6",
        title: "Gestor",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/Socios",
      },
      {
        id: "7",
        title: "Proveedores",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/Proveedores",
      },
    ]
  },
  {
    id: "client",
    title: "Gestión cliente",
    type: "collapse",
    icon: <FaUserPlus size={20} />,
    permissions: ["admin", "editor"],
    badge: "warning",
    badgeText: ".",
    children: [
      {
        id: "1",
        title: "Cliente",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/Clientes",
      },
      {
        id: "2",
        title: "Prospectos",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/prospet",
      }
    ]
  },
  {
    type: "groupHeader",
    groupTitle: "MÓDULO PÓLIZAS"
  },
  {
    id: "policy",
    title: "Gestión pólizas",
    type: "collapse",
    icon: <FaFileAlt size={20} />,
    permissions: ["admin", "editor"],
    badge: "primary",
    badgeText: ".",
    children: [
      {
        id: "1",
        title: "Crear",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/policy",
      },
      {
        id: "2",
        title: "Consultar",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/poliza",
      }
    ]
  },
  /* {
    id: "masiveclients",
    title: "Carga masiva",
    type: "collapse",
    icon: <FaUpload size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "1",
        title: "Clientes",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/masiveclients",
      },
      {
        id: "2",
        title: "Pólizas",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/masivepolicies",
      }
    ]
  }, */
  {
    id: "report",
    title: "Reportes pólizas",
    type: "collapse",
    icon: <FaFileExcel size={20} />,
    permissions: ["admin", "editor"],
    badge: "primary",
    badgeText: ".",
    children: [
      {
        id: "1",
        title: "Producción",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/production",
      },
      {
        id: "2",
        title: "Renovaciones",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/renewal",
      },
      {
        id: "3",
        title: "Cartera",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/briefcase",
      },
      {
        id: "4",
        title: "Por pagar - 15 días",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingforpay",
      },
      {
        id: "5",
        title: "Asegurados",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/insured",
      }
    ]

  },
  /* {
    type: "groupHeader",
    groupTitle: "MÓDULO ARL"
  },
  {
    id: "Arl",
    title: "Arl",
    type: "collapse",
    icon: <FaFileMedical size={20} />,
    permissions: ["admin", "editor"],
    badge: "danger",
    badgeText: ".",
    children: [
      {
        id: "Arl1",
        title: "Crear",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/create_Arl",
      },
      {
        id: "Arl2",
        title: "Consultar",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ListadoTodos",
      },
    ]
  },
  {
    id: "Reinversion",
    title: "Reinversión",
    type: "collapse",
    icon: <FaBalanceScaleRight size={20} />,
    permissions: ["admin", "editor"],
    badge: "danger",
    badgeText: ".",
    children: [
      {
        id: "Reinversion1",
        title: "Crear",
        type: "item",
        icon: <FaCheck size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/Reinversion",
      },
      {
        id: "Reinversion2",
        title: "Consultar",
        type: "item",
        icon: <FaCheck size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/Listado-Reinversion",
      },
      {
        id: "Reinversion3",
        title: "Reportes",
        type: "item",
        icon: <FaCheck size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/Reportes",
      },
    ]
  }, */
]

const navigationAuxiliar = [
  {
    id: "client",
    title: "Gestión cliente",
    type: "collapse",
    icon: <FaUserPlus size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "1",
        title: "Cliente",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/client",
      }
    ]
  },
  {
    id: "policy",
    title: "Gestión pólizas",
    type: "collapse",
    icon: <FaFileAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "1",
        title: "Crear",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/policy",
      },
      {
        id: "2",
        title: "Editar",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/poliza",
      }
    ]
  },
  {
    id: "report",
    title: "Reportes",
    type: "collapse",
    icon: <FaFileExcel size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "1",
        title: "Producción",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/production",
      },
      {
        id: "2",
        title: "Renovaciones",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/renewal",
      },
      {
        id: "3",
        title: "Cartera",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/briefcase",
      },
      {
        id: "4",
        title: "Por pagar - 15 días",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingforpay",
      },
      {
        id: "5",
        title: "Asegurados",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/insured",
      }
    ]

  }
]

const navigationDirection = [
  {
    id: "client",
    title: "Gestión cliente",
    type: "collapse",
    icon: <FaUserPlus size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "1",
        title: "Cliente",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/client",
      }
    ]
  },
  {
    id: "policy",
    title: "Gestión pólizas",
    type: "collapse",
    icon: <FaFileAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "1",
        title: "Crear",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/policy",
      },
      {
        id: "2",
        title: "Editar",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/poliza",
      }
    ]
  },
  {
    id: "report",
    title: "Reportes",
    type: "collapse",
    icon: <FaFileExcel size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "2",
        title: "Renovaciones",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/renewal",
      }
    ]
  }
]

const navigationAssesor = [
  {
    id: "client",
    title: "Gestión cliente",
    type: "collapse",
    icon: <FaUserPlus size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "1",
        title: "Cliente",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/client",
      }
    ]
  },
  {
    id: "policy",
    title: "Gestión de pólizas",
    type: "collapse",
    icon: <FaFileAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "2",
        title: "Listar",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/poliza",
      }
    ]
  }
]

const navigationArl = [
  {
    id: "client",
    title: "Gestión cliente",
    type: "collapse",
    icon: <FaUserPlus size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "2",
        title: "Prospectos",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/prospet",
      }
    ]
  }
]

const navigationConfig =
  user?.profile === 2 ? navigatorAgent :
    user?.profile === 4 ? navigationAuxiliar :
      user?.profile === 43 ? navigationDirection :
        user?.profile === 44 ? navigationAssesor :
          user?.profile === 45 ? navigationArl : []

export default navigationConfig
